import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Airtable from 'airtable';
import { Modal, Button, Form } from 'react-bootstrap';

import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);
const apiKey = 'patmbHZZ055ve4oT6.23f5a1bb2155fc90c77f99a8c96e00fcf7c7e43287afce6d3d6374fa11b40b60';
const baseId = 'appHvBTBmeOLiaQbP';
const tableName = 'tblTKgkNVLEEkcf35';
// const apiKey = 'YOUR_API_KEY';
// const baseId = 'YOUR_BASE_ID';
// const tableName = 'YOUR_TABLE_NAME';
const statusColors = {
    '': 'white',
    'Done': '#CEF5D2',
    'Todo': '#FFD4DF',
    'In progress': '#FFEAB6'
};


function MyCalendar() {
    const [events, setEvents] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalStart, setModalStart] = useState(null);
    const [modalEnd, setModalEnd] = useState(null);
    const [modalStatus, setModalStatus] = useState('');
    const [modalNotes, setModalNotes] = useState(''); // <-- add modalNotes state
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [statusOptions, setStatusOptions] = useState([]); // <-- add statusOptions state
    const [selectedStatusColor, setSelectedStatusColor] = useState('');
    const statusColors = {
        '': 'white',
        'Done': '#CEF5D2',
        'Todo': '#FFD4DF',
        'In progress': '#FFEAB6'
    };

    useEffect(() => {
        // Create a function to fetch data from Airtable and set events state
        const fetchEvents = () => {
            const base = new Airtable({ apiKey }).base(baseId);
            base(tableName).select().all((err, records) => {
                if (err) {
                    console.error(err);
                    return;
                }
                console.log(records); // log the response
                const newEvents = records.map((record) => ({
                    id: record.id,
                    title: record.get('Name'),
                    // start: moment(record.get('Shipping')).toDate(),
                    start: moment(record.get('Shipping'), "YYYY-MM-DD").toDate(),
                    end: moment(record.get('Shipping')).add(1, 'day').toDate(),
                    status: record.get('Status'),
                    notes: record.get('Notes'),
                    allDay: true, // <-- set all-day event flag to true
                }));
                setEvents(newEvents);
                const uniqueStatusValues = [...new Set(records.map(record => record.get('Status')).filter(status => status))];
                setStatusOptions(['', ...uniqueStatusValues]);
            });
        };

        // Fetch initial data
        fetchEvents();

        // Set interval to refresh data every 30 seconds
        const interval = setInterval(() => {
            fetchEvents();
        }, 30000);

        // Clear interval on component unmount to prevent memory leaks
        return () => clearInterval(interval);
    }, []);

    function handleSelect({ start, end }) {
        setShowModal(true);
        setModalTitle('');
        setModalStart(start);
        setModalEnd(end);
        setSelectedEvent(null);
        setModalStatus(''); // <-- add this line
    }

    function handleEventClick(event) {
        setShowModal(true);
        setModalTitle(event.title);
        setModalStart(event.start);
        setModalEnd(event.end);
        setModalStatus(event.status); // <-- set status field value
        setModalNotes(event.notes); // <-- set notes field value
        // setSelectedEvent(event);
        const selectedColor = event.status && statusColors[event.status] ? statusColors[event.status] : 'white'; // <-- add this line to determine the color based on the status
        setSelectedEvent({
            ...event,
            color: event.status ? statusColors[event.status] : 'white',
            allDay: true, // <-- set all-day flag to true
        });

    }

    function handleModalHide() {
        setShowModal(false);
    }

    function handleModalSubmit(event) {
        event.preventDefault();
        let newEventStatus = modalStatus; // <-- add this line
        if (modalStatus === '') {
            newEventStatus = null; // <-- add this line
            setSelectedStatusColor(statusColors['']); // <-- set the default color
        } else {
            setSelectedStatusColor(statusColors[newEventStatus] || 'white');
        }
        const newEvent = {
            title: modalTitle,
            start: modalStart,
            end: modalEnd,
            status: newEventStatus, // <-- update to use newEventStatus
            notes: modalNotes, // <-- add this line
        };
        const base = new Airtable({ apiKey }).base(baseId);
        if (selectedEvent) {
            base(tableName).update(
                [
                    {
                        id: selectedEvent.id,
                        fields: {
                            Name: newEvent.title,
                            Shipping: moment(newEvent.start).format("YYYY-MM-DD"),
                            Status: newEvent.status, // <-- add status field update
                            Notes: newEvent.notes, // <-- add this line
                        },
                    },
                ],
                (err, records) => {
                    if (err) {
                        console.error(err);
                        return;
                    }
                    const updatedEvent = {
                        id: records[0].id,
                        title: records[0].get('Name'),
                        status: records[0].get('Status'),
                        start: moment(records[0].get('Shipping')).toDate(),
                        end: moment(records[0].get('Shipping')).add(1, 'day').toDate(),
                    };
                    const newEvents = events.map((event) =>
                        event.id === updatedEvent.id ? updatedEvent : event
                    );
                    setEvents(newEvents);
                    handleModalHide();
                    // update selectedStatusColor when the status is changed
                    setSelectedStatusColor(statusColors[newEvent.status] || 'white');
                }
            );
        } else {
            base(tableName).create(
                {
                    Name: newEvent.title,
                    Shipping: moment(newEvent.start).format("YYYY-MM-DD"),
                    Status: newEvent.status, // <-- add status field value
                    Notes: newEvent.notes, // <-- add this line
                },
                (err, record) => {
                    if (err) {
                        console.error(err);
                        return;
                    }
                    const newEvent = {
                        id: record.id,
                        title: record.get('Name'),
                        start: moment(record.get('Shipping'), "YYYY-MM-DD").toDate(),
                        end: moment(record.get('Shipping'), "YYYY-MM-DD").add(1, 'day').toDate(),
                        status: record.get('Status'),
                    };
                    const newEvents = [...events, newEvent];
                    setEvents(newEvents);
                    handleModalHide();
                    // update selectedStatusColor when the status is changed
                    setSelectedStatusColor(statusColors[newEvent.status] || 'white');
                }
            );
        }
    }

    useEffect(() => {
        document.documentElement.style.setProperty('--selected-status-color', selectedStatusColor);
    }, [selectedStatusColor]);

    function handleEventDelete() {
        const base = new Airtable({ apiKey }).base(baseId);
        base(tableName).destroy(
            [selectedEvent.id],
            (err, deletedRecords) => {
                if (err) {
                    console.error(err);
                    return;
                }
                const newEvents = events.filter((event) => event.id !== selectedEvent.id);
                setEvents(newEvents);
                handleModalHide();
            }
        );
    }
    function getTextColorBasedOnBackground(bgColor) {
        const luminance = (0.299 * parseInt(bgColor.substring(1,3), 16)) + (0.587 * parseInt(bgColor.substring(3,5), 16)) + (0.114 * parseInt(bgColor.substring(5,7), 16));
        return (luminance > 128) ? '#000000' : '#FFFFFF';
    }
    return (
        <div>
            <DnDCalendar
                localizer={localizer}
                events={events}
                popup
                selectable
                style={{ height: "100vh" }}
                startAccessor="start"
                endAccessor="end"
                defaultView="month"
                views={{ month: true }}
                onSelectSlot={handleSelect}
                onSelectEvent={handleEventClick}
                eventPropGetter={(event) => {
                    const statusColor = statusColors[event.status] || '';
                    const backgroundColor = event.status ? statusColor : '#FFFFFF';
                    const textColor = getTextColorBasedOnBackground(backgroundColor);
                    const style = {
                        backgroundColor,
                        borderColor: '#000000',
                        borderWidth: '1px',
                        color: textColor,
                    };
                    return {
                        className: event.status ? 'rbc-event' : '',
                        style,
                    };
                }}
            />
            <Modal show={showModal} onHide={handleModalHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedEvent ? 'Edit Event' : 'Create Event'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleModalSubmit}>
                        <Form.Group controlId="formBasicTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder=""
                                value={modalTitle}
                                onChange={(e) => setModalTitle(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicStart" className="mb-4">
                            <Form.Label>Start</Form.Label>
                            <Form.Control
                                type="date"
                                value={modalStart ? moment(modalStart).format('YYYY-MM-DD') : ''}
                                onChange={(e) => setModalStart(moment(e.target.value).toDate())}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicEnd" className="d-none">
                            <Form.Label>End</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                value={modalEnd ? modalEnd.toISOString().slice(0, -8) : ''}
                                onChange={(e) => setModalEnd(new Date(e.target.value))}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicStatus">
                            <Form.Label>Status</Form.Label>
                            <select
                                className="form-control status-select"
                                value={modalStatus}
                                onChange={(e) => setModalStatus(e.target.value)}
                                onBlur={() => setSelectedStatusColor(statusColors[modalStatus])} // <-- Add onBlur event
                            >
                                {statusOptions.map((status, index) => (
                                    <option
                                        key={index}
                                        value={status}
                                        data-status={status}
                                        style={{
                                            backgroundColor: statusColors[status],
                                            color: 'black',
                                        }}
                                    >
                                        {status}
                                    </option>
                                ))}
                            </select>
                        </Form.Group>
                        <Form.Group controlId="formBasicNotes">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder=""
                                value={modalNotes}
                                onChange={(e) => setModalNotes(e.target.value)}
                            />
                        </Form.Group>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            {selectedEvent && (
                                <Button variant="danger" className="mx-2" onClick={handleEventDelete}>
                                    Delete event
                                </Button>
                            )}
                            <Button variant="primary" type="submit" className="mx-2 ml-auto">
                                {selectedEvent ? 'Save changes' : 'Create event'}
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
}
export default MyCalendar;