import logo from './logo.svg';
import './App.css';
import MyCalendar from './MyCalendar';
function App() {
  return (
      <div className="App">
        <MyCalendar />
      </div>
  );
}

export default App;
